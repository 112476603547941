/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import styles from "./mobilMenu.module.css";
import LanguageSelector from "../../features/language_selector/LanguageSelector";
import FeatureSelector from "../../features/feature_selector/FeatureSelector.jsx";

export default function MobilMenu() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const handleClickMenu = () => setOpen(!open);

  return (
    <div className={`${styles.header} ${open ? styles.menuOpened : ""}`}>
      <Link to="/" className={location.pathname === "/" || open ? styles.logoBoxHome : styles.logoBoxOther}>
        <img
          className={styles.logo}
          src={location.pathname === "/" || open ? `/Logo-home.webp` : `/Logo-other.webp`}
          alt="synthetic-icon"
        />
        <span className={styles.textLogo}>synthetic</span>
      </Link>
      <div className={styles.burgerContainer} onClick={handleClickMenu}>
        <div className={styles.burger}>
          <div className={`${location.pathname === "/" || open ? styles.barHome : styles.barOther} ${styles.topBar}`} />
          <div className={`${location.pathname === "/" || open ? styles.barHome : styles.barOther} ${styles.btmBar}`} />
        </div>
      </div>

      <ul className={styles.menu}>
        <li className={styles.menuItem}>
          <Link className={styles.btnHref} to="/howitwork" id="workButton" onClick={handleClickMenu}>
            {t("How it works")}
          </Link>
        </li>
        <li className={styles.menuItem}>
          <Link className={styles.btnHref} to="/testyourlanguage" id="languageButton" onClick={handleClickMenu}>
            {t("Test languages")}
          </Link>
        </li>
        <li className={styles.menuItem}>
          <Link className={styles.btnHref} to="/pricing" id="pricingButton" onClick={handleClickMenu}>
            {t("pricing")}
          </Link>
        </li>
        <li className={styles.menuItem}>
          <Link className={styles.btnHref} to="/partners" id="partnersButton" onClick={handleClickMenu}>
            {t("For partners")}
          </Link>
        </li>
        <li className={styles.menuItem}>
          <Link className={styles.btnHref} to="/faq" id="faqButton" onClick={handleClickMenu}>
            FAQ
          </Link>
        </li>
        <li className={styles.menuItem}>
          <Link className={styles.btnHref} to="/blog" id="blogLink" onClick={handleClickMenu}>
            Blog
          </Link>
        </li>
        <li className={styles.menuItem}>
          <Link to="/login" className={styles.logIn} id="loginButton" onClick={handleClickMenu}>
            {t("logIn")}
          </Link>
        </li>
        <li className={styles.menuItem}>
          <Link to="/signup" className={styles.signUp} id="signUpButton" onClick={handleClickMenu}>
            {t("signUp")}
          </Link>
        </li>
        <li className={styles.menuItem} id="languageSelectorMenuItem">
          <LanguageSelector/>
        </li>
      </ul>
    </div>
  );
}
