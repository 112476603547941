/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import styles from "./languageSelector.module.css";
import { setLanguage } from "./languageSlice";
import { setVideoClick } from "../../synthetic/syntheticParamsSlice";

function LanguageSelector() {
  const videoUrl = "https://artificialsynthetic.s3.eu-central-1.amazonaws.com/public-video";
  const { i18n } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const [dropdownList, setDropdownList] = useState(false);
  const dropdownRef = useRef(null);

  const handleLanguageChange = async (language) => {
    i18n.changeLanguage(language);
    setDropdownList(false);
    dispatch(setLanguage(language));
    localStorage.setItem("language", language);

    let newVideoClick;

    if (location.pathname === "/partners") {
      switch (language) {
        case "en":
          newVideoClick = {
            videoPath: `${videoUrl}/AlinaEn.mp4`,
            question: false,
          };
          break;
        case "de":
          newVideoClick = {
            videoPath: `${videoUrl}/AlinaDe.mp4`,
            question: false,
          };
          break;
        case "fi":
          newVideoClick = {
            videoPath: `${videoUrl}/AlinaFi.mp4`,
            question: false,
          };
          break;
        case "fr":
          newVideoClick = {
            videoPath: `${videoUrl}/AlinaFr.mp4`,
            question: false,
          };
          break;
        case "es":
          newVideoClick = {
            videoPath: `${videoUrl}/AlinaEs.mp4`,
            question: false,
          };
          break;
        default:
          newVideoClick = {
            videoPath: `${videoUrl}/AlinaEn.mp4`,
            question: false,
          };
          break;
      }
    } else {
      switch (language) {
        case "en":
          newVideoClick = {
            videoPath: `${videoUrl}/SynIntroEn.mp4`,
            question: false,
          };
          break;
        case "de":
          newVideoClick = {
            videoPath: `${videoUrl}/SynIntroDe.mp4`,
            question: false,
          };
          break;
        case "fi":
          newVideoClick = {
            videoPath: `${videoUrl}/SynIntroFi.mp4`,
            question: false,
          };
          break;
        case "fr":
          newVideoClick = {
            videoPath: `${videoUrl}/SynIntroFr.mp4`,
            question: false,
          };
          break;
        case "es":
          newVideoClick = {
            videoPath: `${videoUrl}/SynIntroEs.mp4`,
            question: false,
          };
          break;
        default:
          newVideoClick = {
            videoPath: `${videoUrl}/SynIntroEn.mp4`,
            question: false,
          };
          break;
      }
    }

    dispatch(setVideoClick(newVideoClick));
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownList(false);
    }
  };

  useEffect(() => {
    const standardVideo = {
      en: `${videoUrl}/SynIntroEn.mp4`,
      de: `${videoUrl}/SynIntroDe.mp4`,
      fi: `${videoUrl}/SynIntroFi.mp4`,
      fr: `${videoUrl}/SynIntroFr.mp4`,
      es: `${videoUrl}/SynIntroEs.mp4`,
    };

    const partnersVideo = {
      en: `${videoUrl}/AlinaEn.mp4`,
      de: `${videoUrl}/AlinaDe.mp4`,
      fi: `${videoUrl}/AlinaFi.mp4`,
      fr: `${videoUrl}/AlinaFr.mp4`,
      es: `${videoUrl}/AlinaEs.mp4`,
    };

    const currentLanguage = i18n.language;
    const isPartnersPage = location.pathname === "/partners";

    const newVideoClick = {
      videoPath: isPartnersPage ? partnersVideo[currentLanguage] : standardVideo[currentLanguage],
      question: false,
    };

    dispatch(setVideoClick(newVideoClick));
  }, [location.pathname, i18n.language, dispatch]);

  useEffect(() => {
    const localLanguage = localStorage.getItem("language");
    if (!localLanguage) {
      const currLang = navigator.language;
      switch (currLang) {
        case "en":
        case "de":
        case "fi":
        case "fr":
        case "es":
          handleLanguageChange(currLang);
          break;
        default:
          handleLanguageChange("en");
          break;
      }
    } else {
      handleLanguageChange(localLanguage);
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className={styles.customDropdown}>
      <button
        type="button"
        className={
          location.pathname === "/" || window.innerWidth <= 768 ? styles.dropdownHeaderHome : styles.dropdownHeaderOther
        }
        onClick={() => setDropdownList(!dropdownList)}
      >
        <img
          // src={location.pathname === "/" || window.innerWidth <= 768 ? `/globe-home.webp` : `/globe-other.webp`}
          src={window.innerWidth <= 768 ? `/globe-other.webp` : `/globe-home.webp`}
          alt="globe"
        />
        <span>{i18n.language.toUpperCase()}</span>
      </button>
      <div
        className={
          dropdownList
            ? location.pathname === "/" || window.innerWidth <= 768
              ? styles.dropdownListActiveHome
              : styles.dropdownListActiveOther
            : styles.dropdownListNoneActive
        }
      >
        <button type="button" onClick={() => handleLanguageChange("en")}>
          EN
        </button>
        <button type="button" onClick={() => handleLanguageChange("de")}>
          DE
        </button>
        <button type="button" onClick={() => handleLanguageChange("fi")}>
          FI
        </button>
        <button type="button" onClick={() => handleLanguageChange("fr")}>
          FR
        </button>
        <button type="button" onClick={() => handleLanguageChange("es")}>
          ES
        </button>
      </div>
    </div>
  );
}

export default LanguageSelector;
