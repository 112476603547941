// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import style from "./partnerPage.module.css";
import BlockPartnerWithUs from "./partner_page_components/partner_with_us/BlockPartnerWithUs";
import BlockHowItWorks from "./partner_page_components/how_it_works/BlockHowItWorks";
import BlockPerfectFor from "./partner_page_components/perfect_for/BlockPerfectFor";
import BlockAlreadyWithUs from "./partner_page_components/already_with_us/BlockAlreadyWithUs";
import BlockContactUs from "./partner_page_components/contact_us/BlockContactUs";
import { setVideoClick } from "../../synthetic/syntheticParamsSlice";

function PartnersPage() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const videoUrl = "https://artificialsynthetic.s3.eu-central-1.amazonaws.com/public-video";

  useEffect(() => {
    return () => {
      dispatch(setVideoClick({ videoPath: "", question: false }));
    };
  }, [dispatch]);

  useEffect(() => {
    const { language } = i18n;
    let newVideoClick;

    switch (language) {
      case "en":
        newVideoClick = {
          videoPath: `${videoUrl}/AlinaEn.mp4`,
          question: false,
        };
        break;
      case "de":
        newVideoClick = {
          videoPath: `${videoUrl}/AlinaDe.mp4`,
          question: false,
        };
        break;
      case "fi":
        newVideoClick = {
          videoPath: `${videoUrl}/AlinaFi.mp4`,
          question: false,
        };
        break;
      case "fr":
        newVideoClick = {
          videoPath: `${videoUrl}/AlinaFr.mp4`,
          question: false,
        };
        break;
      case "es":
        newVideoClick = {
          videoPath: `${videoUrl}/AlinaEs.mp4`,
          question: false,
        };
        break;
      default:
        newVideoClick = {
          videoPath: `${videoUrl}/AlinaEn.mp4`,
          question: false,
        };
        break;
    }

    dispatch(setVideoClick(newVideoClick));
  }, [i18n, dispatch, videoUrl]);

  return (
    <div className={style.mainContainer}>
      <BlockPartnerWithUs />
      <BlockHowItWorks />
      <BlockPerfectFor />
      <BlockAlreadyWithUs />
      <BlockContactUs />
    </div>
  );
}

export default PartnersPage;
